<script setup lang="ts"></script>

<template>
    <svg
        width="146"
        height="136"
        viewBox="0 0 146 136"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g opacity="1">
            <path
                d="M110.733 142.135C102.035 144.251 93.9771 144.566 86.5594 143.081C79.1416 141.596 72.846 138.42 67.6726 133.554C62.4991 128.687 58.9166 122.185 56.9251 114.048L55.8245 109.552L76.7645 104.457L77.8651 108.954C79.5159 115.699 82.8546 120.275 87.8811 122.682C92.8814 124.982 98.979 125.257 106.174 123.506C113.476 121.73 118.545 118.965 121.381 115.212C124.325 111.433 125.246 107.296 124.146 102.799C123.386 99.6944 121.857 97.4005 119.56 95.9176C117.37 94.4085 114.413 93.4831 110.688 93.1414C107.045 92.6666 102.676 92.4817 97.5819 92.5867L93.6806 92.6851C85.5296 92.8531 78.3477 92.3316 72.1349 91.1207C66.0032 89.7765 60.9099 87.329 56.8549 83.7781C52.9074 80.201 50.0951 74.9866 48.418 68.1347C46.741 61.2828 46.9236 55.0561 48.966 49.4546C51.0895 43.72 54.7899 38.8495 60.0671 34.8432C65.4256 30.7036 72.1317 27.6542 80.1855 25.6948C88.2393 23.7354 95.721 23.3899 102.631 24.6582C109.621 25.7934 115.557 28.6599 120.437 33.2579C125.398 37.7227 128.875 44.0234 130.866 52.16L132.045 56.9777L111.105 62.0721L109.926 57.2544C108.878 52.9719 107.18 49.755 104.833 47.6035C102.567 45.3189 99.7333 43.9666 96.3309 43.5465C92.9024 43.0194 89.0404 43.2784 84.745 44.3234C78.3019 45.8909 73.8247 48.2846 71.3132 51.5046C68.883 54.5915 68.1788 58.2226 69.2008 62.3979C69.8821 65.1815 71.1565 67.367 73.0242 68.9545C74.9993 70.5158 77.5937 71.5862 80.8075 72.1655C84.0214 72.7449 88.0013 72.9677 92.7472 72.834L96.6485 72.7356C105.122 72.4892 112.639 72.9859 119.2 74.2255C125.869 75.439 131.405 77.8356 135.808 81.4152C140.211 84.9948 143.251 90.2105 144.928 97.0624C146.605 103.914 146.314 110.394 144.054 116.502C141.876 122.477 137.932 127.747 132.223 132.313C126.594 136.745 119.431 140.019 110.733 142.135Z"
                fill="currentColor"
            />
            <path
                d="M-27 10.7781L15.3297 122.465L52.5652 118.28C53.2586 113.256 54.4579 104.567 54.4579 104.567L56.4649 90.0249C55.4112 89.2929 54.4009 88.5046 53.4353 87.6591L53.4023 87.6302L53.3697 87.6007C48.4446 83.1378 45.2275 76.8908 43.3841 69.3592C41.5099 61.7017 41.6442 54.4144 44.0961 47.6897L44.1049 47.6655C46.595 40.9409 50.9255 35.2946 56.9105 30.7464C59.4872 28.7577 62.3111 27.0146 65.3694 25.507L68.8898 0L46.4825 2.5186L33.5219 106.281L31.2153 106.54L-4.59277 8.25953L-27 10.7781Z"
                fill="currentColor"
            />
        </g>
    </svg>
</template>
